var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tablecolumn" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          key: _vm.tableKey,
          ref: "table",
          attrs: {
            data: _vm.tableData,
            height: "100%",
            border: true,
            "header-cell-style": { color: "#333333", background: "#EFF6FF" },
            "tooltip-effect": "dark",
          },
          on: {
            "header-dragend": _vm.handleHeaderDragEnd,
            "selection-change": _vm.handleSelectionChange,
          },
        },
        _vm._l(_vm.tablecolumn, function (item, index) {
          return _c(
            "div",
            { key: index },
            [
              item.slot
                ? _vm._t(item.slot)
                : item.type == "selection"
                ? _c("el-table-column", {
                    attrs: {
                      fixed: "left",
                      align: "center",
                      type: "selection",
                      width: "55",
                    },
                  })
                : _c("el-table-column", {
                    attrs: {
                      prop: item.prop,
                      align: "center",
                      label: item.label,
                      fixed: item.fixed,
                      "min-width": item.width ? item.width : "",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$empty.empty(scope.row[item.prop])
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }