<template>
  <div class="tablecolumn">
    <el-table
      :data="tableData"
      height="100%"
      v-loading="loading"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      :key="tableKey"
      @header-dragend="handleHeaderDragEnd"
      @selection-change="handleSelectionChange"
      tooltip-effect="dark"
      ref="table"
    >
      <div v-for="(item, index) in tablecolumn" :key="index">
        <slot v-if="item.slot" :name="item.slot"></slot>
        <el-table-column
          v-else-if="item.type == 'selection'"
          fixed="left"
          align="center"
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column
          :prop="item.prop"
          align="center"
          :label="item.label"
          :fixed="item.fixed"
          :min-width="item.width ? item.width : ''"
          show-overflow-tooltip
          v-else
        >
          <template slot-scope="scope">
            {{ $empty.empty(scope.row[item.prop]) }}
          </template>
        </el-table-column>
      </div>
    </el-table>
  </div>
</template><script>
import Sortable from "sortablejs";
export default {
  name: "tablecolumn",
  props: {
    /** 列表加载 */
    loadingchange: {
      type: Boolean,
      default: false,
    },
    /** 数据 */
    tableDatalist: {
      type: Array,
      default: () => [],
    },
    /** 表头数据 */
    tablecolumn: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  data() {
    return {
      tableData: [],
      columns: [],
      loading: false,
      tableKey: 0, // 用于强制重新渲染表格
      sortableInstance: null, // Sortable 实例
    };
  },
  created() {
    this.loading = this.loadingchange;
    this.tableData = this.tableDatalist;
    this.columns = this.tablecolumn;
  },
  mounted() {
    this.initSortable();
  },
  watch: {
    loadingchange: {
      handler(newVal, oldVal) {
        this.loading = newVal;
      },
      deep: true,
      immediate: true,
    },
    tableDatalist: {
      handler(newVal, oldVal) {
        this.tableData = newVal;
      },
      deep: true,
      immediate: true,
    },
    columns: {
      handler(newVal, oldVal) {
        this.$emit("tablechange", newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleSelectionChange(e) {
      this.$emit("Selection", e);
    },
    // 调整宽度 保存宽度
    handleHeaderDragEnd(newWidth, oldWidth, column, event) {
      console.log(newWidth, oldWidth, column, event, column.property, "122");
      // 保存调整后的列宽度
      const columnIndex = this.columns.findIndex(
        (col) => col.prop === column.property
      );

      console.log(columnIndex, "33columnIndex");
      if (columnIndex !== -1) {
        this.columns[columnIndex].width = newWidth;

        this.$nextTick(() => {
          this.$refs.table.doLayout();
        });
        this.initSortable();
      }
    },
    // 拖拽表格列
    initSortable() {
      this.$nextTick(() => {
        if (this.sortableInstance) {
          this.sortableInstance.destroy(); // 销毁之前的实例
        }
        const el = this.$refs.table.$el.querySelector(
          ".el-table__header-wrapper tr"
        );

        this.sortableInstance = Sortable.create(el, {
          onEnd: (evt) => {
            console.log(evt, this.columns, "ve---");

            const movedItem = this.columns.splice(evt.oldIndex, 1)[0];
            console.log(movedItem, "eee");
            this.columns.splice(evt.newIndex, 0, movedItem);

            // 强制重新渲染表格
            this.tableKey += 1;

            // 重新初始化 Sortable
            this.initSortable();
          },
          animation: 150,
          ghostClass: "sortable-ghost",
          onStart: (evt) => {
            if (this.columns[evt.oldIndex].fixed) {
              return false; // 禁止固定列的拖动
            }
          },
        });
      });
    },
    hqlist(e) {
      this.tableData = e.tableData;
    },
  },
};
</script>
<style lang="scss">
.tablecolumn {
  height: 100%;
}
</style>